body, html {
  height: 100%;
  margin: 0;
}
.bg {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  margin: 0; /* Removes default margin */
  padding: 0; /* Removes default padding */
}

/*.grid-item {
  position: relative;
  overflow: hidden;
}
*/

.grid-item .image {
  opacity: 1;
  display:block;
  transition: .3s ease;
}

.grid-item:hover .image {
  opacity: 0.8;
  transform: scale(1.03);
  transform-origin: center;
  -webkit-transform: scale(1.03);
  box-shadow:#000000 0 1px 3px, rgba(255, 255, 255, 0.3) 0 0 0 1px inset, rgba(255, 255, 255, 0.5) 0 1px 0 0 inset;
}

.grid-item:hover .overlay-text {
  visibility: visible;
  opacity: 1;
}